.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App container .Header-nav-item {
     display: block;
 }


.nav-header-buffer {
  height:70px;
}


.nav-footer-buffer {
  height:70px;
}


.nav-footer .navbar-brand {
  font-size: 14px;
  font-weight: normal;
}
