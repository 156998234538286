/*
.Home {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  opacity: 0.3;
}
*/ 


.Home .lander2 {
  padding: 40px 0;
  text-align: center;
  height: 220px;
}

.Home .lander2 h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander2 p {
  color: #999;
}

.Home .Images .pic div {
    padding: 150px 0px;
    width: "100%";
}
