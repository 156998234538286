/*
.Home {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  opacity: 0.3;
}
*/ 


.Home .Contact {
  padding: 10px 0;
  text-align: center;
}

.Home .Contact h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .Contact p {
  color: #999;
}


.Home .Contact button {
    outline: none;
    border: 10px; 
    box-sizing: none; 
    background-color: transparent; 
 }